<template>
  <div class="profile-data-wrapper" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.settings &&
        userPermissions.settings.length > 0 &&
        userPermissions.settings.includes('View')
      "
    >
      <div class="profile-options">
        <div class="row">
          <div class="col">
            <a
              class="btn primary-btn"
              href="javascript:void(0);"
              @click.prevent="openSideModal('addCategory')"
              v-if="userPermissions.settings.includes('Add')"
              >Add new</a
            >
          </div>
          <div class="col flex-grow-1 d-flex justify-content-end">
            <div class="search-container">
              <input
                class="form-control--filled"
                name="search"
                @keyup="performSearch"
                v-model.trim="searchquery"
                placeholder="Search"
                type="text"
              />
              <button type="submit">
                <span class="lnr lnr-magnifier"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-table custom-table">
        <table class="table" id="datatable">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Parent</th>
              <th class="no-sort">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(category, index) in subCategories" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ category.CategoryName }}</td>
              <td>{{ category.parent[0].CategoryName }}</td>
              <td>
                <div>
                  <ul class="d-flex">
                    <li v-if="userPermissions.settings.includes('Update')">
                      <a
                        class="custom-tooltip"
                        v-tippy="`Edit`"
                        href="javascript:void(0);"
                        @click.prevent="openSideModal('editCategory', category)"
                        ><span class="lnr lnr-pencil"></span
                      ></a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <SideModal
        @close="closeSideModal"
        v-bind="{
          contentClass: 'sm-side--modal',
        }"
      >
        <template v-slot:header>
          <template v-if="category.Id">Edit</template
          ><template v-else>Add</template> Sub Category</template
        >
        <template v-slot:body>
          <add-edit-category
            :type="category.type"
            :editCategory="category"
            :categories="categories"
            @refreshList="getCategories"
            @close="closeSideModal"
          />
        </template>
      </SideModal>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.settings &&
        (userPermissions.settings.length === 0 ||
          (userPermissions.settings.length > 0 &&
            !userPermissions.settings.includes('View')))
      "
      >Permission Denied.</template
    >
  </div>
</template>
<script>
import profilePrefix from "../services/profilePrefix.service.js";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import SideModal from "../components/SideModal.vue";
import common from "../mixins/common";
import AddEditCategory from "../components/AddEditCategory.vue";

const type = "sub-category";

export default {
  data: () => ({
    loading: true,
    searchquery: null,
    categories: [],
    subCategories: [],
    category: {
      Id: "",
      name: "",
      type,
      parentId: "",
    },
    modalStatus: false,
  }),
  components: {
    SideModal,
    AddEditCategory,
  },
  mixins: [common],
  methods: {
    async openSideModal(action, category = null) {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      if (
        vm.userPermissions &&
        vm.userPermissions.settings &&
        vm.userPermissions.settings.length > 0 &&
        vm.userPermissions.settings.includes("View")
      ) {
        if (
          action === "addCategory" &&
          vm.userPermissions.settings.includes("Add")
        ) {
          vm.modalStatus = true;
          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
        } else if (
          action === "editCategory" &&
          vm.userPermissions.settings.includes("Update")
        ) {
          vm.modalStatus = true;
          vm.category.Id = category.Id;
          vm.category.name = category.CategoryName;
          vm.category.parentId = category.parent[0].Id;

          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
        }
      }
    },
    closeSideModal() {
      let vm = this;
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        vm.category.Id = "";
        vm.category.name = "";
        vm.category.parentId = "";
        vm.modalStatus = false;
      }, 100);
    },
    async getCategories() {
      let vm = this;

      try {
        vm.$store.commit("loadingStatus", true);
        const response = await profilePrefix.get(type);
        vm.subCategories = response.data;
        vm.categories = response.categories;

        var dT = $("#datatable").DataTable();
        dT.destroy();

        vm.$nextTick(function () {
          $("#datatable").DataTable({
            iDisplayLength: 25,
            bLengthChange: false,
            /* Disable initial sort */
            aaSorting: [],
            columnDefs: [
              { orderable: false, targets: [3] },
              { orderable: true, targets: [0, 1, 2] },
            ],
            info: false,
            fnDrawCallback: function (oSettings) {
              if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
              } else {
                $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
              }
            },
          });
          $(".dt-buttons, .dataTables_filter").hide();
          vm.isLoaded = true;
          vm.performCustomSearch();
        });

        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    performSearch: function () {
      this.performCustomSearch();
    },
    performCustomSearch: function () {
      var oTable = $("#datatable").dataTable();
      var searchText = " ";
      if (this.searchquery != null) {
        searchText += this.searchquery;
      }

      oTable.fnFilter(searchText);
    },
  },
  async mounted() {
    await this.getCategories();
    this.loading = false;
  },
};
</script>
